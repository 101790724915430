import React from "react"
import { graphql, Link } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"

import Layout from "../../components/layout/layout"
import PageTransition from "../../components/transition/page"
import SEO from "../../components/seo/seo"
import Thumbnail from "../../components/thumbnail/thumbnail"
import VideoPlayer from "../../components/player/video"

class TVPage extends React.Component {
  constructor(props) {
    super(props)
    this.pageTransition = React.createRef()
  }
  animate() {
    this.pageTransition.current.play()
  }
  render() {
    const { data, path } = this.props
    const locale = "en-AU"
    const featuredVideo = data.featured.videos[0].video
    const upNextVideos = data.featured.videos.slice(1)
    const videos = data.videos.videos
    return (
      <Layout locale={locale} theme="dark" path={path} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
        <SEO
          title={data.seo.title}
          description={data.seo.description}
          keywords={data.seo.keywords && data.seo.keywords.keywords}
          image={data.seo.image.file.url}
        />
        <section className={"container mx-auto px-0 lg:px-8"}>
          <VideoPlayer source={featuredVideo.video.file.url} autoPlay={true} id={"tv-page"} />
          <div className={"py-4 px-8 lg:px-0 mt-4 mb-12"}>
            <h3 className={"font-agrandir text-white text-xl"}>{featuredVideo.title}</h3>
            <span className={"font-montserrat-regular text-gray-700"}>{featuredVideo.description}</span>
          </div>
        </section>
        <section className={"container mx-auto py-4"}>
          <p className={"uppercase text-white text-lg font-formula-light tracking-widest pl-8 mb-6"}>featured videos</p>
          <div className={"flex flex-wrap mx-auto px-4"}>
            {upNextVideos.map((item, index) => {
              return (
                <div className={"w-full md:w-1/2 lg:w-1/3 px-4"} key={index}>
                  <Link to={`/${locale}/tv/${item.video.slug}`}>
                    <Thumbnail
                      title={item.video.title}
                      description={item.video.description}
                      image={item.video.image.file.url}
                      theme={"dark"}
                      type={"minimal"}
                      video={true}
                    />
                  </Link>
                </div>
              )
            })}
          </div>
        </section>
        <section className={"container mx-auto py-4 my-4"}>
          <p className={"uppercase text-white text-lg font-formula-light tracking-widest pl-8 mb-6"}>all videos</p>
          <div className={"flex flex-wrap px-4"}>
            {videos.map((item, index) => {
              return (
                <div className={"w-full md:w-1/2 lg:w-1/3 px-4"} key={index}>
                  <Link to={`/${locale}/tv/${item.video.slug}`}>
                    <Thumbnail
                      title={item.video.title}
                      description={item.video.description}
                      image={item.video.image.file.url}
                      theme={"dark"}
                      type={"minimal"}
                      video={true}
                    />
                  </Link>
                </div>
              )
            })}
          </div>
        </section>
        <section>
        </section>
        <TransitionPortal>
          <PageTransition ref={this.pageTransition} />
        </TransitionPortal>
      </Layout>
    )
  }
}

export const query = graphql`
  query TVPage {
    seo: contentfulSeo(slug: {eq: "tv"}) {
      title
      description
      keywords {
        keywords
      }
      image {
        file {
          url
        }
      }
    }
    featured: allContentfulVideo(limit: 4, filter: { visible: { eq: true }, node_locale: { eq: "en-AU" } }) {
      videos: edges {
        video: node {
          id
          title
          createdAt
          slug
          description
          image {
            file {
              url
            }
          }
          video {
            file {
              url
            }
          }
        }
      }
    }
    videos: allContentfulVideo(limit: 12, filter: { visible: { eq: true }, node_locale: { eq: "en-AU" } }) {
      videos: edges {
        video: node {
          id
          title
          createdAt
          slug
          description
          image {
            file {
              url
            }
          }
          video {
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default TVPage
