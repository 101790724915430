import React from "react"
import PropTypes from "prop-types"

class VideoPlayer extends React.Component {

  componentDidMount() {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr")
      // window.addEventListener('DOMContentLoaded', function() {
        this.player = new Plyr(`#${this.props.id}`)
      // })
    }
  }

  render() {

    let options = {
      autoPlay: this.props.autoPlay || false,
      poster:this.props.poster,
      muted:"muted",
      controls:true,
      playsInline:true
    }

    return(
      <video id={`${this.props.id}`} {...options}>
        <source src={this.props.source} type="video/mp4" size="720" />
      </video>
    )
  }

}

VideoPlayer.propTypes = {
  source: PropTypes.string,
}

export default VideoPlayer
